<template>
    <div>like.vue</div>
</template>

<script>
export default {
    name: "VideoVue22Like",

    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped>
</style>